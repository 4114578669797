<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Cotización</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Nueva"><b-button icon-left="file" size="is-large" type="is-primary" @click="clearQuotation"></b-button></b-tooltip>
          <b-tooltip label="Ver PDF"><b-button icon-left="eye" size="is-large" :type="hasId ? 'is-primary' :''"  :disabled="!hasId" @click="showPDF = true"></b-button></b-tooltip>
          <b-tooltip label="Guardar"><b-button icon-left="save" size="is-large" :type="isCompleted ? 'is-primary': ''"  :disabled="!isCompleted" @click="onSave"></b-button></b-tooltip>
          <b-tooltip label="Enviar"><b-button icon-left="envelope" size="is-large" :type="hasId ? 'is-primary': ''"  :disabled="!hasId" @click="onSend"></b-button></b-tooltip>
          <b-tooltip
              v-if="patient && patient.lapifan_active && patient.lapifan_active.status"

              label="Lapifan">
            <b-button
                @click="handleLapifanClick"
                size="is-large"
                style="color: gold"
                icon-left="crown"></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="column is-12 is-7-desktop is-8-widescreen is-9-fullhd has-background-white is-multiline">
        <div class="columns is-12 column  is-multiline">
          <div class="column is-4">
            <b-field label="Fecha de cotización"
                     label-position="on-border">
              <b-input :disabled="true" v-model="date"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Folio"
                     label-position="on-border">
              <b-input v-model="folio"
                       @keypress.native.enter="onSearch"
                       @blur="onSearch2"
                       :disabled="hasId"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Vigencia de cotización"
                     label-position="on-border">
              <b-input :disabled="true" v-model="vigency"></b-input>
            </b-field>
          </div>
          <div class="column is-12">
            <select-company
                type="quotation"
                label-position="on-border"
                :company="company"
                :disabled="hasId"
                :clearable="!hasId"
            />
          </div>
          <div class="column is-12" v-if="! hasPatient">
            <select-patient class="columns" type="quotation"
                            :patient.sync="patient"
            ></select-patient>
          </div>
          <div class="column is-6">
            <select-discount-card
                v-if="!hasId"
                type="quotation"
                @input="setDiscountCard"
                @remove-discount-card="removeDiscountCard"
            ></select-discount-card>
            <p v-else-if="discountCard && discountCard.id"><strong>Descuento: </strong>{{discountCard ? discountCard.name : ''}}</p>
          </div>
          <div class="column is-12" v-if="!hasId">
            <b-field grouped group-multiline>
              <p class="control">
                <button class="button field is-danger" @click="clearStudies"
                        :disabled="!checkedRows.length">
                  <b-icon icon="eraser"></b-icon>
                </button>
              </p>
              <p class="control">
                <button :disabled="!checkedRows.length" class="button" @click="onDeleteItems">
                  <b-icon icon="trash"></b-icon>
                </button>
              </p>
              <p class="control">
                <select-study
                    :can-block="false"
                    api-url="price-list"
                    type="quotation"
                    :company="company"
                    @selectStudy="onSelectStudy"
                ></select-study>
              </p>
            </b-field>

          </div>
        </div>
        <div class="column ">
          <b-table
              :checkable="true"
              :checked-rows.sync="checkedRows"
              :data="studies"
              striped
              bordered
              narrowed
              detailed
              sticky-header
              custom-detail-row
              @details-open="onOpenDetail"
          >
            <b-table-column field="code" label="Código" v-slot="props">
              {{ props.row.code }}
            </b-table-column>
            <b-table-column field="name" label="Estudio" v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="delivered_days" label="D.Entrega" width="30" v-slot="props">
              {{ props.row.delivered_days }}
            </b-table-column>
            <b-table-column field="price_show" label="Precio" width="40" v-slot="props" numeric>
              <b-progress v-if="priceLoader"></b-progress>
              <template v-else>
                  <span :class="{'has-text-danger	is-italic': props.row.discount !== 0}">
                     {{ props.row.price_show | currency }}
                </span>
              </template>
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <tr v-for="item in props.row.items" :key="`${props.row.code}-${item.code}`">
                <td></td>
                <td></td>
                <td>
                  <b-collapse :open="false" aria-id="contentIdForA11y1" v-if="item.has_sub_studies">
                    <a @click="onOpenDetail(item)" slot="trigger">{{ item.code }}</a>
                    <table v-if="!!item.items">
                      <tr v-for="sub in item.items">
                        <td>
                          <b-collapse :open="false" aria-id="contentForSubStudies" v-if="sub.has_sub_studies">
                            <a @click="onOpenDetail(sub)" slot="trigger">{{ sub.code }}</a>
                            <table v-if="!!item.items">
                              <tr v-for="subSub in sub.items">
                                <td>
                                  {{ subSub.code }}
                                  {{ subSub.name }}
                                </td>
                              </tr>
                            </table>
                          </b-collapse>
                          <span>
                                {{ sub.code }}
                              </span>
                        </td>
                        <td>{{ sub.name }}</td>
                      </tr>
                    </table>
                  </b-collapse>
                  <span v-else>
                        {{ item.code }}
                      </span>
                </td>
                <td>{{ item.name }}</td>
              </tr>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay estudios seleccionados.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>

      <div class="column is-5-desktop is-4-widescreen is-3-fullhd has-background-white"
           style="border-left: 2px #dbdbdb solid">
        <div style="">
          <div class="column is-12">
            <div class="field is-size-6" style="margin-bottom: 1em">
              <template v-if="hasPatient">
              <div class="is-divider" data-content="Paciente"></div>
              <data-quotation
                :data="patient"
                :read-only="hasId"
                :allow-edit="!hasId"
              ></data-quotation>
              </template>
              <div class="is-divider" data-content="Importe"></div>
              <span class="is-pulled-left">Subtotal:</span>
              <span class="is-pulled-right loader" v-if="priceLoader"></span>
              <span class="is-pulled-right">{{ subTotal | currency }}</span> <br>

              <b-checkbox v-model="withIva">
                IVA:
              </b-checkbox>
                <br>
              <span class="is-pulled-right loader" v-if="priceLoader"></span>
              <span class="is-pulled-right">{{ iva | currency }}</span> <br>
              <span class="is-pulled-left has-text-weight-bold">Total:</span>
              <span class="is-pulled-right loader" v-if="priceLoader"></span>
              <span class="is-pulled-right">{{ total | currency }}</span> <br>
            </div>
            <br>
            <template v-if="$alfalab.enablePaymentFormQuotation(this)">
              <b-field label="Forma de pago">
                <b-select expanded required v-model="paymentForm">
                  <option v-for="method in methods" :key="method.code" :value="method.code">{{method.description}}</option>
                </b-select>
              </b-field>
            </template>
            <br>
            <b-field label="Indicaciones">
              <b-input maxlength="200" type="textarea" disabled v-model="indications"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="`${url}/quotation/preview/${folio}`"></iframe>
    </b-modal>
    <b-modal v-model="showLapifan"  v-if="patient && patient.lapifan_active && patient.lapifan_active.status">
      <lapifan-modal :patient="patient"/>
    </b-modal>
  </div>
</template>

<script>
import SelectCompany from "@/components/company/SelectCompany";
import SelectPatient from "@/components/patient/SelectPatient";
import SelectStudy from "@/components/study/SelectStudy";
import SelectDiscountCard from "@/components/discount/SelectDiscountCard";
import DataQuotation from "@/components/quotation/DataQuotation";
import {mapState, mapGetters} from 'vuex';
import quotation from "@/store/modules/quotation";
import LapifanModal from "../../components/lapifan/LapifanModal.vue";

export default {
  name: "Index.vue",
  components: {
    LapifanModal,
    SelectCompany,
    SelectPatient,
    SelectStudy,
    SelectDiscountCard,
    DataQuotation,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      showPDF: false,
      checkedRows: [],
      columnsStudies: [
        {
          field: 'code',
          label: 'Código',
        },
        {
          field: 'name',
          label: 'Estudio',
        },
        {
          field: 'price_show',
          label: 'Precio',
        },
        {
          field: 'delivered_days',
          label: 'D.Entrega',
        }
      ],
      apiUrl: 'studies',
      showLapifan: false
    }
  },
  computed: {
    ...mapState('quotation', ['studies', 'company', 'priceLoader', 'discountCard', 'vigency', 'date']),
    ...mapState({
      methods: state => state.main.paymentTypes
    }),
    ...mapGetters('quotation', ['hasPatient', 'hasStudies', 'indications', 'hasId', 'subTotal', 'iva', 'total']),
    discountCards() {
      return this.$store.state.main.discountCards;
    },
    isCompleted() {
      return !this.hasId && this.hasPatient && this.hasStudies;
    },
    folio: {
      set(value){
        this.$store.dispatch('quotation/setFolio', value);
      },
      get(){
        return this.$store.state.quotation.folio;
      }
    },
    url() {
      return process.env.VUE_APP_API_URL;
    },
    patient: {
      set(value){
        this.$store.dispatch('quotation/setPatient', value);
      },
      get(){
        return this.$store.state.quotation.patient;
      }
    },
    paymentForm: {
      set(value) {
        this.$store.dispatch('quotation/setPaymentForm', value);
      },
      get() {
        return this.$store.state.quotation.paymentForm;
      }
    },
    withIva: {
      get(){
        return this.$store.state.quotation.withIva;
      },
      set(value) {
        this.$store.dispatch('quotation/setWithIva', value);
      }
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
  methods: {
    clearStudies() {
      this.$store.dispatch('quotation/setStudies', []);
    },
    onDeleteItems() {
      let selectedStudies = this.studies.filter(el => !this.checkedRows.includes(el));
      this.$store.dispatch('quotation/setStudies', selectedStudies.slice());
      this.checkedRows = [];
    },
    onSelectStudy(data) {
      let studies = this.studies;
      if (!studies.find(el => el.code === data.code)) {
        this.$store.dispatch('quotation/addStudy', data);
        this.applyPrices();
        this.changeTaxes(data);
      } else {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: `Es estudio <b>${data.name}</b> ya está <b>agregado</b>.`,
          type: 'is-danger',
          hasIcon: true,
          ariaModal: true
        })
      }
    },
    async onOpenDetail(row) {
      console.log(row);
      let items = row.items || [];
      if(items.length < 1) {
        this.$loader.show();
        await this.getData(row.code).then(({data}) => {
          row.items = data.sub_studies;
        });
        this.$loader.hide();
      }
    },
    applyPrices() {
      if (this.hasStudies) {
        this.$store.state.quotation.priceLoader = true;
        setTimeout(async () => {
          if (!this.isShow) {
            if (!this.readOnly) {
              let studies = this.studies.map(el => el.code);
              if (studies.length) {
                this.$store.state.quotation.priceLoader = true;
                await this.$http.post('filter-studies', {
                  studies,
                  pay_in_cash: true,
                  price_list: this.priceList,
                  company: this.company.company || ''
                }).then(({data}) => {
                  let filterStudies = data.data || [];
                  this.$store.dispatch('quotation/updatePrices', filterStudies);
                }, errors => {
                  this.$toast.error('Sin Datos');
                }).finally(() => {

                });
                this.$store.state.quotation.priceLoader = false;
              }
            } else {
              this.$store.state.quotation.priceLoader = false;
            }
          } else {
            this.$store.state.quotation.priceLoader = false;
          }
        }, 1000);
      }
    },
    clearQuotation() {
      this.$loader.show();
      this.$store.dispatch('quotation/clearQuotation').finally(() => {
        this.$loader.hide();
      });
    },
    setDiscountCard(val) {
      const item = this.discountCards.find(item => {
        return item.id === val;
      });
      this.$store.dispatch('quotation/setDiscountCard', item);
      this.applyPrices();
    },
    removeDiscountCard() {
      this.$store.dispatch('quotation/setDiscountCard', {});
      this.applyPrices();
    },
    onSave() {
      const params = Object.assign({}, {
        studies: this.studies,
        company: this.company,
        patient: this.patient,
        discountCard: this.discountCard,
        ui_version: this.$alfalab.version(),
        withIva: this.withIva ? 1 : 0
      });
      if(this.$alfalab.enablePaymentFormQuotation(this)){
        //console.log('paymentform', this.paymentForm);
        if(!!!this.paymentForm){
          this.$buefy.dialog.alert({
            title: 'Forma de Pago requerida',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            message: 'Es necesario seleccionar una forma de pago para citas de callcenter',
          })
          return;
        }
        params['payment'] = this.paymentForm;
      }
      this.$loader.show();
      this.$http.post('/quotation', params).then(({data}) => {
        console.log(data);
        this.$store.dispatch('quotation/setQuotation', data.quotation);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onSearch(event){
      const value = event.target.value;
      this.$loader.show();
      this.$http.get(`/quotation/${value}`).then(({data}) => {
        this.$store.dispatch('quotation/setQuotation', data.data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSend(){
      this.$loader.show();
      this.$http.post(`/quotation/email/${this.folio}`).then(() => {
        this.$toast.success('Correo enviado');
      }).finally(() => {
        this.$loader.hide();
      });
    },
    onSearch2(event) {
      if (this.$alfalab.mobileAndTabletCheck()) {
        this.onSearch(event);
      }
    },
    changeTaxes(study){
      //verificar si el estudio no requiere iva
      if(!!study.no_tax){
        //mandar alerta
        this.$buefy.dialog.alert({
          title: 'Producto sin IVA',
          message: 'Le recordamos que este producto no incluye IVA y no puede ir en una misma orden con otros productos que si lo incluyan, a menos que se indique lo contrario.',
          confirmText: 'Aceptar'
        });
        //desactivar el order store del iva
        this.$store.dispatch('quotation/setWithIva', false);
      }
    },
    handleLapifanClick() {
      this.showLapifan = true;
    }
  },
  beforeDestroy(){
    this.clearQuotation();
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
